//dependencias
import React, { Component }  from 'react';

//"@tinymce/tinymce-react": "^3.4.0",
//componentes
import AppPrincipal from './AppPrincipal';

  
class  App extends Component {
 
  render() {
    
   return (
      <div className="App">
            <AppPrincipal />         
      </div>
    );
  }
}

export default App;
